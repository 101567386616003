<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
import {storeToRefs} from 'pinia'
import {useUserStore} from '@/store/user'

export default {
  name: 'FormatDate',
  props: {
    date: {
      type: Date,
      default() {
        return null
      }
    },
    includeTime: {
      type: Boolean,
      default() {
        return false
      }
    },
    includeSeconds: {
      type: Boolean,
      default() {
        return true
      }
    },
    separator: {
      type: String,
      default: null
    }
  },
  setup() {
    const {settingValues} = storeToRefs(useUserStore())
    return {
      settingValues
    }
  },
  computed: {
    dateFormat() {
      let format = this.settingValues && this.settingValues.User && this.settingValues.User.DateFormat || 'MM/DD/YYYY HH:mm:ss'
      if (!this.includeTime) {
        format = format.substring(0, 10)
      }
      else if (this.includeTime && !this.includeSeconds) {
        format = format.substring(0, 16)
      }
      if (format.length > 10 && this.separator) {
        format = format.replace('YYYY ', `YYYY ${this.separator} `)
        format = format.replace('yyyy ', `yyyy ${this.separator} `)
      }
      return format
    },
    formattedDate() {
      return this.date && !isNaN(this.date) ? this.$formatDate(this.date, this.dateFormat) : '-'
    }
  }
}
</script>
